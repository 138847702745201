import React from "react"
import Navbar from "./Header/Navbar";
import Footer from "./footer";
import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/global.scss';
import '../styles/media.scss';




export default function Layout({ children}) {
    return (
    <>

            <div>
                <Navbar />
                {children}
                <Footer />
            </div>

    </>
    )
}