import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby";
import { StaticQuery, graphql } from 'gatsby';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
        .schedule {
            background: #093A84;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            color: #fff;
        }
        
  li {
    padding: 10px 5px;
   
        a {
      color: #0c0c0c;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      padding: 7.5px 16px;

      }
  }
  @media (max-width: 1200px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
      
      a {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      padding: 7.5px 16px;
      }
    }
  }
`;

const RightNav = ({ open , data }) => {
  return (
        <StaticQuery
query={graphql`
  query NavbarQuery{
    allContentfulHomePage {
      nodes {
        bookingCalenderUrl
      }
    }
  }
`}
render = {data => (
  data.allContentfulHomePage.nodes.map(node => (
  <Ul open={open}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/#about">Über uns</Link></li>
            <li><Link to="/#services">Leistungen</Link></li>
            <li><Link to="/#testimonials">Referenzen</Link></li>
            <li><Link to="/impressum">Impressum</Link></li>
            <li><Link className="schedule" a href='https://outlook.office365.com/owa/calendar/NovaBauGmbHKalendar@nova-bau.net/bookings/'>Terminvereinbarung</Link></li>
        </Ul>
        ))
)}
  />
  )
}

export default RightNav
