import * as React from "react";
import {Link} from "gatsby";
import {FaFacebookSquare} from 'react-icons/fa';

const Footer = () => (
    <>
        <footer>
            <div class="pb-5 footer">
                <div class="container">
                    <div class="row">

                        <div className="col-lg-4 col-md-4 col-xs-12 location">
                            <h4 className="mt-lg-0 mt-sm-4 text-uppercase">Kontakt</h4>
                            <p>Nova Bau GmbH<br/>
                            Brigachstraße 6<br/>
                            78048 Villingen-Schwenningen</p>
                            <p className="mb-0">Telefon: <a href="tel:077218077909">077218077909</a></p>
                            <p className="mb-0">Fax: 077219160242</p>
                            <p className="mb-0"><a href="mailto:info@nova-bau.net">info@nova-bau.net</a></p>
                        </div>

                        <div class="col-lg-3 col-md-4 col-xs-12 links">
                            <h4 class="mt-lg-0 mt-sm-3 text-uppercase">Links</h4>
                            <ul class="m-0 p-0">
                                <li><Link to="/impressum">Impressum</Link></li>
                                <li><Link to="/agb">AGBs</Link></li>
                                <li><Link to="/datenschutz">Datenschutz</Link></li>

                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-4 col-xs-12">
                            <h4 className="mt-lg-0 mt-sm-3 text-uppercase">Social Media</h4>
                            <p><a href="https://www.facebook.com/Nova.Bau.Villingen/"><FaFacebookSquare style={{color: '#3a559f', fontSize: '44px'}}/></a></p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col copyright">
                            <p class=""><small class="text-white-50">© {new Date().getFullYear()} Alle Rechte sind geschützt.</small>
                            <small class="text-white-50"> Erstellt von  <a class="text-secondary text-decoration-none" href="https://www.digiasset.vision" target="_blank" >
        [digi]<span>asset</span>
        </a></small></p>
                       
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    </>
)

export default Footer;
