import React, {useState, useEffect} from "react";
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import Burger from "./Burger";

const Nav = styled.nav`
  width: 100%;
  height: 96px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
   img {
    height: 96px;
   }
`


const Navbar = ({data}) => {

    const [stickyClass, setStickyClass] = useState('relative');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return() => {
            window.removeEventListener('scroll', stickNavbar);
        };
    },[]);

    const stickNavbar = () => {
        if (window !== undefined ) {
            let windowHeight = window.scrollY;
            windowHeight > 500 ? setStickyClass('fixed bg-white') : setStickyClass('relative');
        }
    }

    return (

        <Nav className={`navbar ${stickyClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <div className="logo">
                            <a className="navbar-brand" href="#">
                                <StaticImage alt="NovaBau logo"
                                             src="../../assets/images/logo.png"
                                             placeholder="blurred"
                                             layout="fixed"
                                             width={107}

                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-10 col-lg-9 menu-container">
                        <Burger data={data}/>
                    </div>
                </div>
            </div>
        </Nav>

    )
}




export default Navbar;